<template>
    <header class="page-title">
        <h1><i class="bi bi-gear"></i> 標準労務単価設定</h1>
    </header>

    <section class="section">
        <div class="row mb-3">
            <div class="col-6">
                <label class="form-label">氏名</label>
                <div class="form-readonly" v-if="!loading">{{ user.user_name }}</div>
                <div class="form-readonly" v-else><text-loader :width="6"></text-loader></div>
            </div>
        </div>

        <template v-if="loading">
            <div class="row align-items-end">
                <template v-for="i in Array(12)" :key="i">
                    <div class="col-4 mt-3">
                        <label class="form-label"><text-loader :width="4"></text-loader></label>
                        <div class="input-group">
                            <form-input-number disabled></form-input-number>
                            <span class="input-group-text">円</span>
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="btn btn-primary" disabled>保存</button>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="row align-items-end">
                <template v-for="labor_cost of labor_costs" :key="labor_cost.month">
                    <div class="col-4 mt-3">
                        <label class="form-label">{{ labor_cost.month_display }}</label>
                        <div class="input-group">
                            <form-input-number v-model="labor_cost.unit_cost"></form-input-number>
                            <span class="input-group-text">円</span>
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="btn btn-primary" @click="save(labor_cost)">保存</button>
                    </div>
                </template>
            </div>
        </template>
    </section>
</template>

<script>
import FormInputNumber from '@/components/forms/FormInputNumber';
import TextLoader from '@/components/tools/TextLoader';
import User from "@/models/entities/user";
import LaborCost from "@/models/entities/labor-cost";

export default {
    name: 'SystemUserLaborCost',
    components: {
        FormInputNumber,
        TextLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            user_id: parseInt(this.$route.params.user),
            user: new User(),
            labor_costs: [],
        }
    },
    mounted() {
        this.fetchUser();
    },
    methods: {
        fetchUser() {
            this.$http.get(`/system/user/${this.user_id}`)
            .then((response) => {
                this.user = new User(response.data);
                this.fetchLaborCosts();
            });
        },
        fetchLaborCosts() {
            this.labor_costs.splice(0);
            this.$http.get(`/system/user/${this.user_id}/labor-cost`)
            .then((response) => {
                for (let row of response.data) {
                    this.labor_costs.push(new LaborCost(row));
                }
                this.loading = false;
            });
        },
        save(labor_cost) {
            this.startScreenLoading();
            this.$http.put(`/system/user/${this.user_id}/labor-cost/${labor_cost.month}`, labor_cost)
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
}
</script>

<style scoped>
</style>
