/**
 * ユーザマスタ
 */
import { isBlank } from '@/utilities/typing'
import { displayMonth } from '@/utilities/month'

class LaborCost {
    user_id;
    month;
    unit_cost;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.user_id = properties.user_id;
        this.month = properties.month;
        this.unit_cost = properties.unit_cost;
    }

    // 在席期間表示
    get month_display() {
        return displayMonth(this.month);
    }
}

export default LaborCost;
